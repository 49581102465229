import { initializeApp } from 'firebase/app';
import * as fanalytics from "firebase/analytics";
import * as fauth from 'firebase/auth';
import * as fmessaging from "firebase/messaging";

let firebaseConfig = {
  apiKey: "AIzaSyA9p5NXlfnQIe-5wPcyE1zCOrJG-Xp6-yg",
  authDomain: "dashboard-kritsnam.firebaseapp.com",
  projectId: "dashboard-kritsnam",
  storageBucket: "dashboard-kritsnam.appspot.com",
  messagingSenderId: "138141256285",
  appId: "1:138141256285:web:e3abae58a60cd4c14ddbb4"
};
const app = initializeApp(firebaseConfig);

export const environment = {
  production: true,
  test: false,
  fauth: fauth,
  fanalytics: fanalytics,
  fmessaging: fmessaging,
  fcmVapidKey: 'BF7A6CH5HhZqtUAxIJ5IZnsY5_IHDby56vQhDNV-4gPNytIt6nJmRE2XhDIZC-emopwmjKSQ9znkL8c4k5ekzw0',
  apiUrl: 'https://api-live-staging.dhaara.com',
  jwtAllowedDomain: 'api-live-staging.dhaara.com',
  rollbarAccessToken: '08237933e1c9461a8e92aa781d525a92',
  rollbarEnv: 'staging',
  recaptchaApiKey: "6LfnI2UdAAAAAA7IsAJLN6SIANIbCkC1b2iBO_0f",
  bannerPubKey: '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYhkknSLaPE9oudVF4cPkRybd5fpM7P4Qr3moJynGBzO+1t/9GFd4carLnXwhuvYlnaUJnenhlOJJiGOl8hS/HAYTAQQJBxRm7p5dlV6+85ExcWOZdJEmEJzj3z8ccl5zG/SxEb6PABWt+9G4INb7incvv70kAJWbrwziMbsrwQwIDAQAB-----END PUBLIC KEY-----',
  posthogId: 'phc_9BmeltR0dkBTu2WlWTb091izLIxF5phnHhd5s9KhVD2',
};
